.social-links {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
}

.social-links__item {
  position: relative;

  display: block;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin: 0 $side-gap;
  padding: 5px;

  border-radius: 12px;


  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    border-radius: 0.25rem;
    opacity: 0;

    transition: opacity $short-duration;
  }
  &:hover::before {
    opacity: 1;
  }

  a {
    position: relative;

    display: block;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: white;
  }

  &--facebook {
    display: none;
    padding: 0;

    background: center no-repeat linear-gradient(to bottom, #0062e0, #19afff);
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &--vk:before {
    background-color: #4680c2;
  }

  &--telegram:before {
    background-color: #27a7e5;
  }

  &--instagram::before {
    background: center no-repeat;
    // background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000);
    background-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-color: $gray-100;

  @media (min-width: $md) {
    background-image: url(../img/bg_dark.webp);
    background-attachment: fixed;
  }
}

.page__header {
  position: sticky;
  top: 0;
  z-index: 2;

  padding: 0 $side-gap;

  background-color: white;
  box-shadow: $box-shadow;

  animation: opacityUp 0.5s;
}

.page__main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.page__anonses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-top: $spacer;
  padding: 0 $grid-gutter-width;
  overflow: hidden;
}

.page__banner {
  margin-bottom: 0;
}

.page__title {
  padding: 0;

  @extend .container;
}

.page__title-wrap {
  margin-bottom: 0;
  padding: $spacer $side-gap;

  color: $over-primary;

  background-color: $primary;
}

.page__local-menu {
  position: sticky;
  top: 0;
  z-index: 1;

  display: none;

  @extend .d-sm-block;
}

.page__side-menu {
  padding: 0 $side-gap;

  div {
    display: flex;
    flex-direction: column;
    margin: 0 auto $spacer;
    padding: 1rem $side-gap;

    background-color: $primary;
    border-radius: 0.5rem;
    box-shadow: $box-shadow;

    @media (min-width: $sm) {
      flex-direction: row;
    }
  }
  h3 {
    margin-right: $spacer;
    margin-bottom: $spacer;

    color: $over-primary;
  }
  ul {
    margin: 0 (-$spacer) (-$spacer);
    padding: 0;

    list-style: none;
  }
  li {
    margin: 0 $spacer $spacer $spacer;

    text-align: left;

    border: 1px solid white;

    @extend .btn;
    @extend .btn-primary;
  }
  a {
    color: $over-primary;
  }
}

.page__content {
  z-index: 0;

  flex-grow: 1;
}

.page__footer {
  padding: 1rem $side-gap;
}

.page__admin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  color: $over-primary;

  background-color: black;

  section {
    margin-bottom: 2rem;
    padding: 1rem $side-gap;
  }
}

.catalog__items {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(($body-min-width - (2 * $side-gap)), 1fr));
  // margin: $side-gap;

  // gap: $side-gap;

  @extend .page__anonses;
}

.catalog__item {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  min-width: $body-min-width - (2 * $side-gap);
  max-width: 1.5 * $body-min-width - (2 * $side-gap);
  margin: 0 $side-gap 3rem;

  padding: $side-gap;

  background-color: white;
  border-radius: 0.5rem;
  box-shadow: $box-shadow;
}

.catalog__product-name {
  font-size: 1.5rem;
}


.catalog__price--ribbon {
  position: absolute;
  right: -10px;
  bottom: -10px;

  width: 150px;
  height: 150px;
  overflow: hidden;

  font-weight: bold;
  font-size: 19px;
  line-height: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;

    display: block;

    border: 5px solid darken($secondary, 10%);;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  & span {
    position: absolute;
    bottom: 30px;
    left: -25px;

    display: block;
    width: 225px;
    padding: 15px 0;

    text-align: center;
    color: #ffffff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

    background-color: $secondary;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transform: rotate(-45deg);
  }
  &::before {
    bottom: 0;
    left: 0;
  }
  &::after {
    top: 0;
    right: 0;
  }
}

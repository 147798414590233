@keyframes opacityDown {
  0% {
    opacity: 1;
  }
}

@keyframes opacityUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fallDown {
  0% {
    transform: translateZ(0);
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popUp {
  0% {
    transform: translateZ(0);
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes leftToZero {
  0% {
    transform: translateZ(0);
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rightToZero {
  0% {
    transform: translateZ(0);
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

.main-menu {
  display: block;
  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  background-color: $primary;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: #00000080;
  }

  @media (min-width: $sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: $md) {
    flex-wrap: nowrap;
    justify-content: left;

    background-color: white;

    &::before {
      display: none;
    }
  }
  @media (min-width: $lg) {
  }
}

.main-menu__item {
  background-color: inherit;
  cursor: pointer;

  transition: all $short-duration;

  &:hover,
  &--hover {
    background-color: darken($primary, 7.5%);
    @media (min-width: $md) {
      background-color: $primary;
    }
  }
}

.main-menu__link {
  position: relative;

  display: block;
  margin: 0 auto;
  padding: 1rem;

  font-size: $font-size-base;
  text-align: center;
  color: $over-primary;
  text-transform: uppercase;
  white-space: nowrap;

  background: none;
  border: none;

  &.active,
  &:hover {
    color: $over-primary;
  }

  @media (min-width: $md) {
    margin: 0 0 auto 0;

    text-align: left;
    color: $primary;
    text-transform: unset;

    .folder--opened & {
      text-align: left;
      color: $primary;
    }

    .main-menu__item:hover > &,
    &:hover,
    &.active:hover {
      color: $over-primary;
    }

    &.active {
      color: $link-active-color;
    }

    &:hover {
      color: $over-primary;
    }
  }
}

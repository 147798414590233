/* uncss:ignore start */
.content {
  display: flex;
  flex-direction: column;

  opacity: 1;

  animation: opacityUp 0.5s 1;

  img {
    margin-bottom: $spacer;
  }

  h2 {
    margin-bottom: 1rem;
    padding-top: 3rem;

    color: $primary;
    column-span: all;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: $paragraph-margin-bottom;

    font-size: 2rem;
    color: $primary;
    column-span: all;
  }

  em {
    font-style: normal;
    color: $emphasis-color;
  }

  a:hover:not(.content__button) {
    text-decoration: underline;
  }

  ul {
    margin-top: -$paragraph-margin-bottom;
    margin-bottom: $paragraph-margin-bottom;
    padding-left: 1rem;

    list-style: none;
  }

  ul li::before {
    content: "";
    position: relative;
    bottom: 1px;

    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    margin-left: -1rem;

    background-color: $primary;
    border-radius: 50%;
  }

  .price {
    font-size: 1.5rem;
    line-height: 1em;
    color: $emphasis-color;
  }
}

.content__wrap {
  flex-grow: 1;
}

.content__wrap,
.content__aside {
  padding: $spacer $side-gap;

  background-color: white;

  @extend .container;
}

.content__section {
  clear: both;
  margin-top: 3rem;
  padding-top: 1px;

  background: radial-gradient($link-hover-color, transparent 75%);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: auto 2px;
}

.content__columns {
  margin-bottom: $spacer;

  column-count: 2;
  column-gap: 2rem;
  column-rule: 1px solid lightgray;

  @media (max-width: 991px) {
    column-count: 1;
  }

  & li {
    display: block;

    break-inside: avoid;
  }
}

.content__gallery {
  display: grid;

  gap: $side-gap;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  a {
    align-self: center;

    box-shadow: $box-shadow;

    justify-self: center;
  }

  img {
    margin: 0;

    cursor: zoom-in;

    transition: $short-duration;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.content__flex {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$grid-gutter-width/2;
  margin-bottom: $spacer;
  margin-left: -$grid-gutter-width/2;

  > * {
    @extend .col-md-6;
  }

  > img {
    align-self: center;
  }
}

.content__flex--lg {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacer;

  > * {
    @extend .col-lg-6;
  }
}

.content__button {
  display: block;
  align-self: center;
  padding: 0.75rem;

  font-size: 2rem;
  line-height: 1;

  @extend .btn;
  @extend .btn-primary;
}

.content__table {
  clear: both;
  width: 100%;
  overflow-x: auto;
  &--small {
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      clear: none;
      width: none;
    }
  }
}

.content__intro {
  display: flex;
  flex-wrap: wrap;
  img,
  figure {
    max-width: 100%;
    margin-left: 0;
  }
  :nth-child(2) {
    order: -1;
  }

  @media (min-width: $lg) {
    display: block;
    > img,
    > figure,
    > picture {
      float: right;
      width: 100%;
      max-width: $lg / 2 - $side-gap * 2;
      margin-left: $side-gap;
    }
  }

  &--third {
    @media (min-width: $md) {
      display: block;
      > img,
      > figure,
      > picture {
        float: right;
        width: 100%;
        max-width: $lg / 3 - $side-gap * 2;
        margin-left: $side-gap;
      }
    }
  }
}

.content__contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 !important;
  padding: 0 !important;

  font-size: 1.5rem;
  line-height: 3rem;

  li::before {
    content: none !important;
  }

  a {
    margin-right: 4rem;

    background-repeat: no-repeat;
    background-position: left center;
    svg {
      position: relative;
      bottom: 2px;

      width: auto;
      height: 1.25rem;
      margin-right: 0.5rem;

      fill: $primary;
    }
    &:hover svg {
      fill: $link-hover-color;
    }
  }
}

.content__blockquote {
  padding-left: 1.25rem;

  font-style: italic;
  font-size: 1.25rem;

  border-left-width: 6px;
  border-left-style: double;
  border-left-color: scale-color($secondary, $lightness: 50%);
}
/* uncss:ignore end */

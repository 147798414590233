.main-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 0;
  padding-left: 20px;
  margin-left: -20px;

  background-color: inherit;
}

.main-nav__logo {
  height: 40px;
  margin-top: 0.25rem;
  margin-right: auto;
  margin-bottom: 0.25rem;
}

.main-nav__navbar {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;

  box-shadow: black 0 10px 10px -10px;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    position: relative;

    margin-right: -$side-gap;
    margin-left: -$side-gap;

    border-bottom: none;
    box-shadow: none;
  }
}

.main-nav__toggler {
  position: relative;
  z-index: 2;

  align-self: stretch;
  margin-right: -$side-gap;
  margin-left: $side-gap;
  padding-right: $side-gap;
  padding-left: $side-gap;

  background-color: $primary;
  border: none;
  border-radius: 0;

  &:hover {
    background-color: darken($primary, 7.5%);
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    display: none;
  }
}

.main-nav__toggler-icon {
  transition: stroke-width $short-duration;

  will-change: d;

  d: path("M5 5 L25 25 M5 25 L25 5");
  stroke: white;
  stroke-linecap: round;
  stroke-width: 3;

  .collapsed & {
    will-change: d;

    d: path("M2 5 h26 M2 15 h26 M2 25 h26");
  }

  .main-nav__toggler:hover & {
    stroke-width: 4;
  }
}

.table {
  width: unset;

  text-align: center;

  border: (2 * $table-border-width) solid $table-border-color;

  caption {
    padding-top: $table-cell-padding;
    padding-bottom: $table-cell-padding;

    caption-side: top;
    font-weight: bold;
    text-align: left;
    color: $secondary;
  }

  th,
  td {
    padding: 0.5rem 1rem;

    vertical-align: middle !important;

    border-bottom: $table-border-width solid $table-border-color;
    .small {
      white-space: nowrap;
    }
  }

  th {
    text-align: left;
  }

  thead th:not([scope="row"]) {
    text-align: center;
  }

  tbody td:hover {
    outline: red 1px solid;
    // cursor: pointer;
  }

  tbody tr:hover:not(.table__row-head) {
    td,
    th:nth-last-of-type(1) {
      background-color: rgba(255, 0, 0, 0.1);
    }
    th:nth-last-of-type(1) {
      color: red;
    }
  }

  .highlighted {
    background-color: rgba(255, 0, 0, 0.1) !important;
  }

  th.highlighted {
    color: red;
  }

  ul,
  ol,
  p {
    margin: 0;
  }
}

.table--poor {
  tbody tr:hover {
    td,
    th:nth-last-of-type(1) {
      background-color: transparent;
    }
    th:nth-last-of-type(1) {
      color: red;
    }
  }
}

.table--list {
  th:first-child,
  td:first-child {
    text-align: left;
  }
  th:last-child,
  td:last-child {
    white-space: nowrap;
  }
}

.table__cg-0 {
  background-color: scale-color($secondary, $lightness: 95%);
}

.table__cg-1 {
  background-color: scale-color($orange, $lightness: 95%);
}

.table__cg-2 {
  background-color: scale-color($primary, $lightness: 95%);
}

.table__notes {
  font-size: $small-font-size;
}

.table__text--optional {
  display: none;
  @media (min-width: $lg) {
    display: inline;
  }
}

.table__row-head {
  color: $secondary;

  & th {
    padding-top: 1rem;

    text-align: center;
  }
}

.offer {
  position: relative;

  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  padding: 10px;

  text-align: center;

  border-width: 2px;
  border-style: solid;
  border-color: $offer-color;
  border-radius: 0.5rem;

  p {
    max-width: unset;
  }
}

.offer__ribbon {
  position: relative;

  display: block;
  width: calc(100% + 42px);
  margin-bottom: 20px;
  margin-left: -21px;

  font-weight: bold;
  font-size: 3em;
  color: white;

  background: $offer-color;
  border-top: 1px dashed;
  border-bottom: 1px dashed;
  box-shadow: 0 3px $offer-color, 0 -3px $offer-color, 0 21px 5px -18px rgba(0, 0, 0, 0.6);

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -13px;

    width: 0;
    height: 0;

    border-top: 10px solid #555555;
  }

  &:before {
    left: 0;

    border-left: 10px solid transparent;
  }

  &:after {
    right: 0;

    border-right: 10px solid transparent;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    border-radius: 0.5rem;
  }
}

.offer ol,
.offer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;

  list-style: none;

  counter-reset: ol-counter;
}

.offer li {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  margin: 0.5rem 1rem 1rem;
  padding: 0.5rem 1rem;

  text-align: left;

  border: 1px $offer-marker-color dashed;
  border-radius: 0.5rem;

  counter-increment: ol-counter;

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    width: calc(50% - 2rem);
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    width: calc(33% - 2rem);
  }

  &::before {
    content: counter(ol-counter);
    position: absolute;
    top: -1rem;
    left: -1rem;

    width: 2rem;
    height: 2rem;
    margin: 0;

    text-align: center;
    color: white;

    background: $offer-marker-color;
    border-radius: 50%;
  }

  @media (min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "md") - 1px) {
    &:nth-child(3) {
      flex-direction: row;
      width: 100%;
    }

    &:nth-child(3) img {
      width: 50%;
    }
  }
}

.offer ul li {
  justify-content: flex-start;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    width: calc(50% - 2rem);
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: calc(33% - 2rem);
  }
  &::before {
    content: none !important;
  }
}

.offer__desc {
  svg {
    position: relative;
    bottom: 2px;

    width: auto;
    height: 15px;
    margin-right: 4px;

    fill: $primary;
  }
  a:hover svg {
    fill: $link-hover-color;
  }
}

@keyframes introNotice {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notice {
  position: fixed;
  top: 1rem;
  right: 1rem;
  left: 1rem;
  z-index: 1000;

  font-size: x-large;
  text-align: center;
  color: white;

  cursor: pointer;
  animation: introNotice 4s;

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    top: 2rem;
    left: 2rem;

    // width: 60%;
    // margin-left: -30%;
  }
  @media (min-width: map-get($grid-breakpoints, "md")) {
    top: 25%;
    left: 50%;

    width: 60%;
    margin-left: -30%;
  }
}

.notice__section {
  padding: 40px 40px 90px 40px;
}

.notice::after {
  content: "OK";
  position: absolute;
  bottom: 15px;
  left: 50%;

  padding: 10px 20px;

  color: rgb(255, 125, 0);

  background-color: white;
  border-radius: 0.5rem;
  transform: translateX(-50%);
}

.notice::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
}

.notice__section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-color: $orange;
  border-radius: 1rem;
  box-shadow: white 0 0 10px 5px;
}

.banner {
  position: relative;

  min-height: 100vh;
  overflow: hidden;

  color: #ffffff;
  text-shadow: 0 0 0.5rem black;

  background-color: $primary;
  background-image: url("../img/belka-printoffice-bw.jpg");
  background-position: center top;
  background-size: auto 100%;
  background-attachment: fixed;
}

.banner__wrap {
  padding: $spacer $side-gap;

  animation: opacityUp $short-duration $short-duration;
}

.banner__title-image {
  position: absolute;
  left: 0;

  display: none;
  width: 100%;
  min-height: 200px;

  background-repeat: no-repeat;
  background-position: calc(50% + 200px) calc(50% + 15px);
  background-size: 400px, auto;
  opacity: 1;

  animation-name: opacityUp;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;

  @extend .d-md-block;
}

.banner::before {
  opacity: 0.75;

  animation: opacityDown 0.6s ease-in;

  @include overlay;
}

.banner a {
  color: inherit;
  &:hover,
  &:focus {
    color: $link-hover-color;
  }
}

.banner__title {
  position: relative;

  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-left: 0.5rem;
  overflow: hidden;

  font-style: normal;
  font-weight: bold;
  font-size: 6rem;

  @media (min-width: $md) {
    animation: fallDown 2*$short-duration $short-duration $easeOutBack both;
  }
}

.banner__slogan {
  position: relative;

  margin-bottom: 2rem;
  padding: 0 0 0 0.5rem;

  font-size: 3.0rem;
  @media (min-width: $md) {
    animation: popUp 2*$short-duration 2*$short-duration $easeOutBack both;
  }
}

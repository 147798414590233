/* uncss:ignore start */
.controls {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;

  display: flex;
  justify-content: center;

  background-color: black;

  .nav-link {
    color: white;
    &:hover,
    &.active {
      color: $orange;
    }
  }
}

.element-invisible {
  @extend .sr-only;
}
/* uncss:ignore end */

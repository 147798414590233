.anons {
  display: flex;
  flex-grow: 1;
  flex-basis: $body-min-width;
  max-width: 1.5 * $body-min-width - (2 * $side-gap);
  margin-bottom: 3rem;
  padding-right: $side-gap / 2;
  padding-left: $side-gap / 2;

  @media (min-width: $xl) {
    flex-basis: $body-min-width * 1.25;
    max-width: 1.75 * $body-min-width - (2 * $side-gap);
  }

  img {
    width: 100%;
    margin-bottom: $paragraph-margin-bottom;
  }
}

.anons__wrap {
  position: relative;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
  overflow: hidden;

  background-color: white;
  border-radius: 0.5rem;
  box-shadow: $box-shadow;

  transition: box-shadow $short-duration;

  @media (min-width: $md) {
    .anons--odd & {
      animation-name: opacityUp;
      animation-duration: 2s;
      animation-delay: 0.5;
    }
    .anons--even & {
      animation-name: opacityUp;
      animation-duration: 2s;
      animation-delay: 0.5;
    }
  }

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba($black, 0.5);
  }
}

.anons__heading {
  padding-top: 0 !important;

  font-size: $h3-font-size;
  color: $primary;
}

.anons__readmorebutton {
  min-height: 45px;
  // width: calc(100% + 2rem);
  margin: auto -1rem 0;
  padding: 0.5rem 1rem;

  border-radius: 0;

  @extend .btn;
  @extend .btn-primary;

  &:hover {
    text-decoration: none !important;
  }
}

.main-contacts {
  display: flex;
  margin: 0;

  font-size: 16px;

  a {
    margin-left: 15px;

    line-height: 40px;

    svg {
      position: relative;
      bottom: 2px;

      height: 15px;
      margin-right: 4px;

      fill: $primary;
    }
    &:hover svg {
      fill: $link-hover-color;
    }
  }
}

.main-contacts__phone {
  display: none;

  @extend .d-md-inline;
}

.main-contacts__cellphone {
  display: none;

  @extend .d-sm-inline;
}

.main-contacts__geo span {
  display: none;
  @media (min-width: $sm) {
    display: inline;
  }
}

.main-contacts__mail {
  display: none;

  @extend .d-lg-inline;
}

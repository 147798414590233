.webform {
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;

  > fieldset:not(:first-child) {
    margin-bottom: 2 * $spacer;
    padding: $spacer $side_gap;

    border-width: 2px;
    border-color: $link-hover-color;

    @extend .card;
  }

  legend {
    width: unset;
    padding: 0 0.5rem;

    color: $link-hover-color;
  }
  .form-item {
    @extend .custom-control;
  }
  .form-type-radio {
    @extend .custom-radio;
  }
  .form-type-checkbox {
    @extend .custom-checkbox;
  }
  .form-number {
    display: initial;
    width: unset;

    @extend .form-control;
  }
  .custom-file-label::after {
    content: ". . .";
  }
  .description {
    font-size: $small-font-size;
  }
  .error {
    background-image: none;
    outline: solid 2px red;
  }
  .messages {
    padding: $spacer $side_gap;
  }
  .remove-button {
    @extend .btn;
    @extend .btn-danger;
  }
  .file-icon {
    margin: 0;
  }
  .form-actions {
    position: sticky;
    bottom: 0;
    z-index: 1;

    min-height: 140px;
    margin: 0 (-$side_gap);
    margin-bottom: -16px;
  }
}

.webform-component__wrap {
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
}

.webform-component {
  margin-bottom: $spacer;

  input.email:invalid {
    border-color: $red;
  }
  input.email:valid {
    border-color: $green;
  }

  &--customer--consent {
    color: $red;
    & :checked + label {
      color: $green;
    }
  }

  &--parameters,
  &--customer {
    .webform-component__wrap {
      align-items: flex-end;

      @extend .row;
      > * {
        @extend .col-sm-6;
      }
    }
  }
  &--parameters--comment,
  &--parameters--amount,
  &--customer--consent,
  &--customer--terms {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  &--parameters--amount {
    select {
      width: unset;

      vertical-align: baseline;
    }
    .description {
      display: inline-block !important;
      margin-left: 1em;
    }
  }
  &--option {
    .form-radios {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$grid-gutter-width/2;
      margin-left: -$grid-gutter-width/2;

      .form-item {
        padding-right: $grid-gutter-width/2;
        padding-left: $grid-gutter-width/2;
      }

      > * {
        flex-grow: 1;
        margin: 0;
        margin-bottom: $spacer;
      }

      input[type="radio"] {
        display: none;
      }

      label {
        position: relative;

        display: block;
        height: 100%;
        padding: 2rem 1rem;
        padding-bottom: 7rem;

        font-size: 1.5rem;
        text-align: center;

        background-color: $gray-200;
        background-repeat: no-repeat;
        background-position: bottom 1rem center;
        background-size: 10rem; // Для IE
        background-size: var(--bgsz, 5rem);// Для нормальных браузеров
        border: 2px solid $gray-500;
        border-radius: 0.25rem;
        box-shadow: 0 0 0.5rem $gray-500;
        cursor: pointer;

        transition:$short-duration;

        &::before,
        &::after {
          display: none;
        }

        &[for="edit-submitted-option-1"] {
          background-image: url("../img/upload.svg");
        }
        &[for="edit-submitted-option-2"] {
          background-image: url("../img/address-card.svg");
        }
      }

      label:hover,
      .form-radio:checked + label {
        color: white !important;

        background-color: $link-hover-color;
        border-color: $link-hover-color;
        box-shadow: 0 0 0.5rem $link-hover-color;
      }
    }
  }
}

.webform--active {
  .webform-component {
    &--intro {
      display: none;
    }
    &--design {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    &--option {
      margin-bottom: 0;
      padding: 0;

      border: none;

      .form-radios {
        flex-wrap: unset;
        > * {
          margin-bottom: 0;
        }

        label {
          padding: 0.5rem;

          font-size: 1rem;
          color: $gray-500;

          background-position: left 10px center;
          background-size: 20px;
          border-bottom: transparent;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          &:not(:hover) {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.logged-in .form-actions {
  bottom: 40px;
}

.total {
  display: grid;
  align-items: end;
  padding: $spacer $side_gap 0;
  padding-bottom: $spacer;

  justify-items: center;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    justify-content: space-between;

    grid-template-columns: 1fr 1fr;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    display: block;
    width: 100vw;
    height: 100%;
    margin-left: calc(50% - 10px);

    background-color: mix(white, $primary, 90%);
    border-top: 2px dashed $secondary;
    transform: translateX(-50%);
  }

  .form-submit {
    font-size: 1.5rem;

    @extend .btn;
    @extend .btn-secondary;
  }
}

.total__summary {
  display: none;
  flex-basis: 100%;
  max-width: none;

  font-size: $small-font-size;

  grid-row: 1/3;

  @media (min-width: $md) {
    display: block;
  }
}

.total__sum {
  font-size: 2rem;
  line-height: 3rem;
}

.total__price {
  color: red;
}

.total__warning {
  position: relative;

  display: none;

  &--invalid {
    display: flex;
    justify-content: flex-start;

  }

  &-item {
    margin-right: 1rem;
    &--false {
      color: $red;
      &::before {
        content: "☒ ";
      }
    }
    &--true {
      color: $green;
      &::before {
        content: "☑ ";
      }
    }
  }
}

.figures {
  display: flex;
  align-items: flex-start;
  margin-left: -($side-gap) / 2;
  margin-right: -($side-gap) / 2;

  img {
    cursor: zoom-in;
    width: 100%;
  }

  > * {
    padding-left: ($side-gap) / 2;
    padding-right: ($side-gap) / 2;

    &:nth-child(n + 3) {
      display: none;
      @media (min-width: $md) {
        display: block;
      }
    }
  }

}

figcaption {
  font-size: $small-font-size;
  text-align: center;
}

.figures--3col {
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    flex-basis: 40%;
    flex-grow: 1;

    @media (min-width: $md) {
      flex-basis: 30%;
    }

    &:nth-child(n + 5) {
        display: block;
    }
  }
}

.figures--2col {
  display: grid;
  grid-template-columns: 1fr 1fr;

  > * {
    &:first-child {
      grid-row: 1/3;
    }

    flex-basis: 50%;

    @media (min-width: $md) {
      flex-basis: 33%;
    }

    &:nth-child(n + 5) {
        display: block;
    }
  }
}

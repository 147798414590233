.footer {
  z-index: 1;

  font-size: $small-font-size;
  color: $gray-500;

  background-color: #17191c;

  em {
    font-style: normal;
    color: $footer-link-color;
  }
}

.footer__title {
  font-size: 1.875rem;
}

.footer__wrap {
  @media (max-width: map-get($grid-breakpoints, "sm") - 1px) {
    max-width: $max-col-width;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-left: -15px;
  }

  a,
  b {
    font-weight: normal;
    color: $footer-link-color;
  }
  a:hover {
    color: white;
  }
}

.footer__section {
  &:not(:last-child) {
    margin-bottom: 2rem;

    @extend .col-sm-6;
    @extend .col-md-4;
    @extend .col-xl-3;
    @extend .mb-md-0;
  }
  &:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;

    @extend .col-md-4;
    @extend .col-xl-3;
  }

  @media (max-width: map-get($grid-breakpoints, "sm") - 1px) {
    &:not(:last-child)::after {
      content: "";

      display: block;
      height: 2px;
      margin-top: 1rem;

      border-bottom: 1px dashed $gray-500;
    }
  }
}

.footer__copyright {
  margin-top: 1rem;

  text-align: center;
}

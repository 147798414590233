/* uncss:ignore start */
.folder {
  position: relative;

  cursor: pointer;

  &--opened {
    overflow: unset;
  }
}

.folder__wrap {
  position: relative;
  top: 0;
  z-index: 1;

  display: block;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;

  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  opacity: 0;

  transition-duration: 0.5s;
  transition-property: height, opacity;

  &--show {
    opacity: 1;
  }

  @media (min-width: $sm) {
    position: absolute;
    top: 62px;
  }
  @media (min-width: $md) {
    border-color: $primary;
    border-radius: 0;
  }
}

.folder--opened .folder__toggle {
  color: $over-primary;
}

.folder__toggle:after {
  content: "";

  display: inline-block;
  margin-left: 0.255em;

  vertical-align: 0.255em;

  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.folder__menu {
  display: block;
  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  background-color: $primary;

  @media (min-width: $md) {
    background-color: mix(white, $primary, 90%);
  }
}

.folder--more {
  position: absolute;
  right: 0;

  & > .folder__toggle:after {
    display: none;
  }

  .folder__wrap {
    right: 0;
  }

  .folder__wrap .folder__wrap {
    position: static;

    height: auto;

    opacity: 1;
  }

  .folder {
    border-bottom: 0.5rem solid $primary;
    border-left: 0.5rem solid $primary;
  }
}
/* uncss:ignore end */

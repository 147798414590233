.local-menu {
  justify-content: center;

  background-color: white;
  box-shadow: $box-shadow;
  overflow-x: hidden;

  @extend .nav;
}

.local-menu__item {
  position: relative;

  animation: leftToZero 2*$short-duration 4*$short-duration $easeOutBack both;
}

.local-menu__link {
  display: block;
  padding: 0.5rem 1rem;

  &.active {
    color: $link-active-color;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;

    display: block;
    width: 0;
    height: 0.25rem;
    margin-right: auto;
    margin-left: auto;

    background-color: $link-hover-color;
    border-radius: 0.125rem 0.125rem 0 0;
    transform: translateX(-50%);
    opacity: 0;

    transition: width $short-duration;
  }

  &:hover::before,
  &.active::before {
    width: 100%;

    opacity: 1;
  }
}

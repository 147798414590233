@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: "Didact Gothic";

  font-display: swap;
  src: url("../fonts/didact-gothic-cyr-regular.woff2") format("woff2");
  // src: url("../fonts/didact-gothic-cyr-regular.woff") format("woff");
}
@font-face {
  font-style: normal;
  font-weight: bold;
  font-family: "Didact Gothic";

  font-display: swap;
  src: url("../fonts/didact-gothic-cyr-bold.woff2") format("woff2");
  // src: url("../fonts/didact-gothic-cyr-bold.woff") format("woff");
}


body {
  position: relative; // needed for bootstrap scrollSpy

  min-width: $body-min-width;

  color: $gray-700;
  &::before {
    z-index: -1;

    opacity: 0.15;

    @include overlay;
  }
}

img {
  @include img-fluid();
}

:focus,
button:focus {
  outline: opacify($primary, 0.3) dotted 1px;
}

address {
  margin-bottom: $paragraph-margin-bottom;
}
